import React, { useEffect } from 'react';
import { graphql } from 'gatsby';
import useMediaChange from 'common/useMediaChange';

import { Toaster } from 'react-hot-toast';
import RegisterBetaMain from '@sections/RegisterBeta/Main';
import RegisterBetaRegister from '@sections/RegisterBeta/Register';
import RegisterBetaRewards from '@sections/RegisterBeta/Rewards';
import Divider from '@global/Dividers';

import './styles.scss';

export const query = graphql`
	query ($language: String!) {
		locales: allLocale(filter: { language: { eq: $language } }) {
			edges {
				node {
					ns
					data
					language
				}
			}
		}
    allS3Object {
      nodes {
        localFile {
          relativePath
          childImageSharp {
            gatsbyImageData(placeholder: NONE)
            fluid(maxWidth: 1920, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
    allFile {
      edges {
        node {
          relativePath
          childImageSharp {
            gatsbyImageData(placeholder: NONE)
            fluid(maxWidth: 1920, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
	}
`;

const RegisterBeta = () => {
  const { isMobile } = useMediaChange();

  useEffect(() => {
    setTimeout(() => {
      const element = document.getElementById('register-section');
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    }, 1500);
  }, [])

  return (
    <>
      <main className='passwordless-connect'>
        <Toaster />
        <div className='flex flex-col'>
          <RegisterBetaMain isMobile={isMobile} />
          <RegisterBetaRegister isMobile={isMobile} />
          <Divider dividerImage='black-divider-upwards-1' />
          <RegisterBetaRewards isMobile={isMobile} />
        </div>
      </main>
    </>
  )
}

export default RegisterBeta;
