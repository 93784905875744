import React, { FC } from 'react'
import { useTranslation } from 'gatsby-plugin-react-i18next';

import { IRegisterBetaProps } from 'common/types';

import Brand from 'images/brand.png';

const RegisterBetaMain: FC<IRegisterBetaProps> = ({ isMobile }) => {
  const { t } = useTranslation();

  return (
    <>
      <section
        className={`
              relative
              ${isMobile ? 'hero-background-mobile' : 'hero-background-desktop'}
            `}>
        <img
          src={Brand}
          alt={t('registerBeta.logoAlt')}
          className='
                absolute
                transform
                top-[10%]
                left-1/2
                -translate-x-1/2
                w-1/2
                min-w-[200px]
                sm:max-lg:top-[5%]
                sm:w-1/4
                sm:max-w-[463px]
              '
        />
      </section>
    </>
  )
}

export default RegisterBetaMain;
