import React, { FC } from 'react'
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { useUserContext } from 'common/UserProvider';

import { StylizedButton } from '@global/Buttons';
import RadioButtonIcon from '@global/RadioButton/RadioButtonIcon';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';

const RegisterAssets: FC<any> = ({ isMobile, handleSubmit }) => {
  const { t } = useTranslation();
  const { betaRegisterData, setBetaRegisterEmail, setDevice, resetRegisterBetaData } = useUserContext()

  const registerForBeta = async (email: string, device: string) => {
    const success = await handleSubmit(email, device);
    if (!success) {
      return;
    }

    resetRegisterBetaData();
  }

  return (
    <>
      <div
        className='
          flex
          flex-col
          space-y-[28px]
          sm:space-y-4
          w-full
        '
      >
        <input
          id='email-input'
          placeholder={t('registerBeta.register.placeholder')}
          type='email'
          value={betaRegisterData?.emailAddress || ''}
          onChange={(e) => setBetaRegisterEmail(e.target.value)}
          className='mokens-input-register h-[50px] sm:h-[85px] w-full'
        />
        <FormControl
          sx={{
            '& .MuiTypography-root': {
              fontFamily: 'Public Sans, helvetica'
            }
          }}
        >
          <label
            className={`
                    font-semibold
                    text-black
                    ${isMobile ? 'text-base' : 'text-[20px]'}
                  `}
          >
            {t('registerBeta.register.deviceLabel')}
          </label>
          <RadioGroup
            aria-labelledby='device-radio-buttons-group-label'
            name='device-radio-buttons-group'
            className='
                    flex
                    w-full
                    border-[3px]
                    border-black
                    border-solid
                    rounded-xl
                    justify-around
                    lg:w-1/2
                  '
            value={betaRegisterData?.selectedDevice || ''}
            onChange={(e) => setDevice((e.target as HTMLInputElement).value)}
            row
          >
            <FormControlLabel
              value='Android'
              label='Android'
              control={
                <Radio
                  icon={<RadioButtonIcon />}
                  checkedIcon={<RadioButtonIcon checked />}
                />}
            />
            <FormControlLabel
              value='iOS'
              label='iOS'
              control={
                <Radio
                  icon={<RadioButtonIcon />}
                  checkedIcon={<RadioButtonIcon checked />}
                />}
            />
          </RadioGroup>
        </FormControl>
      </div>
      <StylizedButton
        text={t('registerBeta.register.ctaButton')}
        onClick={() => registerForBeta(betaRegisterData?.emailAddress, betaRegisterData?.selectedDevice)}
        className='h-[50px] sm:h-[85px]'
      />
    </>
  )
}

export default RegisterAssets
