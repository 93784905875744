import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'gatsby-plugin-react-i18next';

import { submitRegistration } from 'common/utils';
import { IRegisterBetaProps } from 'common/types';

import { BP, H1, HP } from 'components/Global/Texts';
import RegisterAssets from './RegisterAssets';

import './styles.scss';

const RegisterBetaRegister: FC<IRegisterBetaProps> = ({ isMobile }) => {
  const { t } = useTranslation();

  const [referCode, setReferCode] = useState<string>('');

  useEffect(() => {
    const params = new URLSearchParams(document.location.search);
    setReferCode(params.get('refer_code'));
  }, []);

  const runTranslate = (sentence: string) => t(`${sentence}`)

  return (
    <>
      <section
        id='register-section'
        className={`
          flex
          flex-col
          items-center
          relative
          ${isMobile ? 'register-background-mobile' : 'register-background-desktop'}
        `}
      >
        <div
          className='
            flex
            flex-col
            w-[95%]
            pt-10
            pb-[15%]
            space-y-6
            text-white
            sm:pt-24
            sm:pb-[28%]
            sm:space-y-10
            md:w-9/12
            xl:pb-[20%]
            xl:max-w-7xl
          '
        >
          <H1
            className={`
              text-center
              md:text-left
              ${isMobile ? 'bold-header-mobile' : 'bold-header-desktop'}
            `}
          >
            {t('registerBeta.register.title')}
          </H1>
          <div className='text-left'>
            <HP className='text-center md:text-left'>
              {t('registerBeta.register.description1.part1')}
            </HP>
            <HP className='text-center md:text-left'>
              {t('registerBeta.register.description1.part2')}
            </HP>
            <HP className='text-center md:text-left'>
              {t('registerBeta.register.description1.part3')}
            </HP>
            <HP className='text-center md:text-left'>
              {t('registerBeta.register.description1.part4')}
            </HP>
          </div>
          <BP className='text-center md:text-left'>
            {t('registerBeta.register.description2')}
          </BP>
          <div
            className='
              flex
              flex-col
              w-full
              items-start
              gap-[28px]
              sm:flex-row
              sm:gap-4
              sm:h-[85px]
              lg:min-w-[750px]
              lg:max-w-[60%]
            '
          >
            <RegisterAssets
              isMobile={isMobile}
              handleSubmit={
                (emailAddress, selectedDevice) =>
                  submitRegistration(emailAddress, selectedDevice, runTranslate, referCode)
              }
            />
          </div>
        </div>
      </section>
    </>
  )
}

export default RegisterBetaRegister;
