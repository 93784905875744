import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { IRadioButtonProps } from 'common/types';

import CheckIcon from 'images/check-black.svg';

const RadioButtonIcon: FC<IRadioButtonProps> = ({ checked = false }) => {
  const { t } = useTranslation();

  return (
    <span
      className='
        relative
        w-6
        h-6
        border-[2px]
        border-black
        border-solid
        bg-white
        rounded-md
      '
    >
      {checked && <img src={CheckIcon} alt={t('registerBeta.checkAlt')} />}
    </span>
  )
}

export default RadioButtonIcon;
