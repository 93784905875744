import React, { FC } from 'react';
import { useTranslation, useI18next } from 'gatsby-plugin-react-i18next';

import { IRegisterBetaProps } from 'common/types';
import { navigateRedirect } from 'common/utils';
import { LOGIN_URL } from 'common/const';

import { H1, HP } from '@global/Texts';
import { StylizedButton } from '@global/Buttons';

import RewardsPack from 'images/register-beta/Rewards-pack.png';
import MokensFiller from 'images/register-beta/Moken-filler.png';

const RegisterBetaRewards: FC<IRegisterBetaProps> = ({ isMobile }) => {
  const { t } = useTranslation();
  const { language, languages } = useI18next();

  return (
    <>
      <section
        className={`
              relative
              lg:pb-20
              ${isMobile ? 'rewards-background-mobile' : 'rewards-background-desktop'}
            `}
      >
        <img
          src={RewardsPack}
          alt={t('registerBeta.rewards.packAlt')}
          className='
                absolute
                w-full
                transform
                left-1/2
                -translate-x-1/2
                -top-[8%]
                sm:-top-[12%]
                z-10
              '
        />
        <div
          className='
                flex
                flex-col
                w-[95%]
                m-auto
                space-y-5
                mt-[60%]
                mb-4
                text-white
                md:w-9/12
                md:space-y-8
                xl:max-w-7xl
              '
        >
          <H1
            className={`
                  text-center
                  md:text-left
                  ${isMobile ? 'bold-header-mobile' : 'bold-header-desktop'}
                `}
          >
            {t('registerBeta.rewards.title')}
          </H1>
          <div className='text-center md:text-left md:space-y-8'>
            <HP>
              {t('registerBeta.rewards.description1')}
            </HP>
            <HP>
              {t('registerBeta.rewards.description2')}
            </HP>
          </div>
          <StylizedButton
            text={t('registerBeta.rewards.linkButton')}
            link={navigateRedirect(LOGIN_URL, language, languages)}
            className='
              flex
              self-center
              h-[50px]
              sm:h-[85px]
              md:self-start
            '
          />
        </div>
        <img
          src={MokensFiller}
          alt={t('registerBeta.rewards.fillerAlt')}
          className='
                absolute
                right-0
                bottom-0
                hidden
                w-[50%]
                lg:block
                xl:w-[40%]
              '
        />
      </section>
    </>
  )
}

export default RegisterBetaRewards;
