import React, { FC } from 'react';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { graphql, useStaticQuery } from 'gatsby';

import { IDividerProps } from 'common/types';
import { getBucketImage } from 'common/utils';

import { GatsbyImage } from 'gatsby-plugin-image';

import './styles.scss';

const Divider: FC<IDividerProps> = ({
	dividerImage,
	middleImage,
	size = 'lg'
}) => {
	const { t } = useTranslation();

	const data = useStaticQuery(graphql`
      query AllImages {
        allS3Object {
          nodes {
            localFile {
              relativePath
              childImageSharp {
                gatsbyImageData(placeholder: NONE)
              }
            }
          }
        }
      }
    `)

	return (
		<div className={`
			flex
			relative
			flex-col
			justify-center
			`}
		>
			{middleImage && <GatsbyImage
				image={getBucketImage(data, middleImage, 'imageData')}
				alt={t('registerBeta.dividerAlt')}
				objectFit='cover'
				imgClassName='overflow-visible'
				className={`
					${size === 'lg' ? 'w-5/12' : size === 'md' ? 'w-1/4' : 'w-1/6'}
					absolute
					z-20
					self-center
					transform
					dividerImage
				`}
			/>}
			{dividerImage && <GatsbyImage
				image={getBucketImage(data, dividerImage, 'imageData')}
				alt={t('registerBeta.dividerAlt')}
				className='absolute z-10 w-full aspect-[1920/151]'
			/>}
		</div>
	)
}

export default Divider;